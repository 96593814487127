import request from '@/utils/request';

// 服务订单列表
export function getServiceOrderLists(data) {
  return request({
    url: 'order-web-api/admin/orderservice/getList',
    method: 'post',
    data
  });
}

// 导出服务订单
export function exportServiceOrder(data) {
  return request({
    url: 'order-web-api/admin/orderservice/getList',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

// 加购订单列表
export function getOrderLists(data) {
  return request({
    url: 'order-web-api/admin/order/getOrderLists',
    method: 'post',
    data
  });
}

// 导出加购订单
export function exportOrderLists(data) {
  return request({
    url: 'order-web-api/admin/order/getOrderLists',
    method: 'post',
    data,
    responseType: 'blob'
  });
}

export default {};
