<template>
  <div class="wrap">
    <div class="top">
      <div class="top-title"><span class="title-line"></span> 当前待办</div>
      <div v-loading="waitDoLoading" class="top-bigBox">
        <div v-for="(n, i) in waitDoData" :key="i" class="top-box" @click="goRefund(n.status)">
          <div class="num">{{ n.count }}</div>
          <div>{{ n.statusName }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="top-title">
        <span class="left"><span class="title-line"></span>支付统计</span>
        <div>
          <span class="time">支付时间</span>
          &emsp;
          <el-radio-group v-model="radio">
            <el-radio :label="1">今日</el-radio>
            <el-radio :label="2">昨日</el-radio>
            <el-radio :label="3">近7天</el-radio>
            <el-radio :label="4">近30天</el-radio>
            <el-radio :label="5">今年</el-radio>
            <el-radio :label="6">自定义</el-radio>
            <el-date-picker v-if="radio === 6" v-model="value" size="small" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-radio-group>
        </div>
      </div>
      <div v-loading="midLoading" class="mid">
        <div class="left">
          <div class="text">
            <div class="money-title">新增支付</div>
            <div class="money"><span class="unit">¥</span>{{ money.total }}</div>
            <!-- <div class="desc">不含已退款</div> -->
          </div>
          <div class="line"></div>

          <div class="money-box">
            <div class="money-item">
              <span class="name">微信</span>
              <el-progress color="#00A199" :show-text="false" :stroke-width="18" :percentage="money.vxPercentage"></el-progress>
              <span class="name">¥{{ money.vx }}</span>
            </div>
            <div class="money-item">
              <span class="name">支付宝</span>
              <el-progress color="#2861E2" :show-text="false" :stroke-width="18" :percentage="money.zfbPercentage"></el-progress>
              <span class="name">¥{{ money.zfb }}</span>
            </div>
            <div class="money-item">
              <span class="name">银行卡</span>
              <el-progress color="#E86247" :show-text="false" :stroke-width="18" :percentage="0"></el-progress>
              <span class="name">¥{{ money.bankCard }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="text">
            <div class="money-title">新增支付</div>
            <div class="money">{{ num.total }}<span class="unit">笔</span></div>
            <!-- <div class="desc">不含已退款</div> -->
          </div>
          <div class="line"></div>

          <div class="money-box">
            <div class="money-item">
              <span class="name">微信</span>
              <el-progress color="#00A199" :show-text="false" :stroke-width="18" :percentage="num.vxPercentage"></el-progress>
              <span class="name">{{ num.vx }}笔</span>
            </div>
            <div class="money-item">
              <span class="name">支付宝</span>
              <el-progress color="#2861E2" :show-text="false" :stroke-width="18" :percentage="num.zfbPercentage"></el-progress>
              <span class="name">{{ num.zfb }}笔</span>
            </div>
            <div class="money-item">
              <span class="name">银行卡</span>
              <el-progress color="#E86247" :show-text="false" :stroke-width="18" :percentage="0"></el-progress>
              <span class="name">{{ num.bankCard }}笔</span>
            </div>
          </div>
        </div>
      </div>
      <div v-loading="totalLoading" class="total">
        <div class="left">
          <div class="name">申请退款</div>
          <div class="val">{{ totalData.totalCount }}<span class="unit">笔</span></div>
          <div class="val"><span class="unit">¥</span>{{ totalData.totalAmount }}</div>
        </div>
        <div class="right">
          <div v-for="(n, i) in statDtoList" :key="i" class="text">
            <div class="line"></div>
            <div>
              <div class="name">{{ n.statusName }}</div>
              <div class="val">{{ n.count }}笔</div>
              <div class="val">￥{{ n.amount }}</div>
            </div>
          </div>
          <!-- <div class="text">
            <div class="line"></div>
            <div>
              <div class="name">退款中</div>
              <div class="val">2笔</div>
              <div class="val">￥0.40</div>
            </div>
          </div>
          <div class="text">
            <div class="line"></div>
            <div>
              <div class="name">退款失败</div>
              <div class="val">1笔</div>
              <div class="val">￥0.01</div>
            </div>
          </div>
          <div class="text">
            <div class="line"></div>
            <div>
              <div class="name">已退款</div>
              <div class="val">10笔</div>
              <div class="val">￥2.29</div>
            </div>
          </div>
          <div class="text">
            <div class="line"></div>
            <div>
              <div class="name">已拒绝退款</div>
              <div class="val">0笔</div>
              <div class="val">￥0</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryReport, getRefundStatistics, getCurrentRefundStatis } from '@/api/pay'
import moment from 'moment'
export default {
  name: 'Index',
  data() {
    return {
      radio: 1,
      value: '',
      statistics: [],
      money: {
        total: 0,
        vx: 0,
        zfb: 0,
        bankCard: 0,
        vxPercentage: 0,
        zgbPercentage: 0,
      },
      num: {
        total: 0,
        vx: 0,
        zfb: 0,
        bankCard: 0,
        zfbPercentage: 0,
        vxPercentage: 0,
      },
      dateForm: {
        beignDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
      midLoading: false,
      waitDoData: [
        { count: 0, status: 0, statusName: '申请退款' },
        { count: 0, status: 5, statusName: '退款失败' },
        { count: 0, status: 3, statusName: '退款中' },
      ],
      waitDoLoading: false,
      statDtoList: [
        { amount: '0', count: 0, status: 0, statusName: '申请中' },
        { amount: '0', count: 0, status: 3, statusName: '退款中' },
        { amount: '0', count: 0, status: 5, statusName: '退款失败' },
        { amount: '0', count: 0, status: 4, statusName: '已退款' },
        { amount: '0', count: 0, status: 2, statusName: '已拒绝退款' },
      ],
      totalData: {
        totalAmount: 0,
        totalCount: 0,
      },
      totalLoading: false,
    }
  },
  watch: {
    radio: function (val) {
      val = Number(val)
      if (val === 1) {
        this.dateForm.beignDate = moment().format('YYYY-MM-DD')
        this.dateForm.endDate = moment().format('YYYY-MM-DD')
      }
      if (val === 2) {
        this.dateForm.beignDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
        this.dateForm.endDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
      }
      if (val === 3) {
        this.dateForm.beignDate = moment().subtract(6, 'days').format('YYYY-MM-DD')
        this.dateForm.endDate = moment().format('YYYY-MM-DD')
      }
      if (val === 4) {
        this.dateForm.beignDate = moment().subtract(29, 'days').format('YYYY-MM-DD')
        this.dateForm.endDate = moment().format('YYYY-MM-DD')
      }
      if (val === 5) {
        this.dateForm.beignDate = moment().format('YYYY') + '-01-01'
        this.dateForm.endDate = moment().format('YYYY-MM-DD')
      }
      if (val !== 6) {
        this.queryReport()
        this.getRefundStatistics()
      }
    },
    value: function (val) {
      if (val && val.length > 0) {
        this.dateForm.beignDate = moment(val[0]).format('YYYY-MM-DD HH:mm:ss')
        this.dateForm.endDate = moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
        console.log(this.dateForm)
        this.queryReport()
        this.getRefundStatistics()
      }
    },
  },
  mounted() {
    this.queryReport()
    this.getCurrentRefundStatis()
    this.getRefundStatistics()
  },
  methods: {
    goRefund(e) {
      this.$router.push({
        path: '/refund/list',
        query: { status: e },
      })
    },
    getRefundStatistics() {
      this.totalLoading = true
      getRefundStatistics({
        data: {
          startTime: this.dateForm.beignDate,
          endTime: this.dateForm.endDate,
        },
      })
        .then((res) => {
          this.totalLoading = false
          if (res) {
            if (res.totalCount) {
              this.totalData = res
              res.statDtoList.forEach((e) => {
                this.statDtoList.forEach((v) => {
                  if (e.status === v.status) {
                    v.count = e.count
                    v.amount = e.amount
                  }
                })
              })
            } else {
              this.totalData = {
                totalAmount: 0,
                totalCount: 0,
              }
              this.statDtoList = [
                { amount: '0', count: 0, status: 0, statusName: '申请中' },
                { amount: '0', count: 0, status: 3, statusName: '退款中' },
                { amount: '0', count: 0, status: 5, statusName: '退款失败' },
                { amount: '0', count: 0, status: 4, statusName: '已退款' },
                { amount: '0', count: 0, status: 2, statusName: '已拒绝退款' },
              ]
            }
          }
        })
        .catch(() => {
          this.totalLoading = false
        })
    },
    getCurrentRefundStatis() {
      this.waitDoLoading = true
      getCurrentRefundStatis({ data: {} }).then((res) => {
        this.waitDoLoading = false
        res.forEach((e) => {
          this.waitDoData.forEach((v) => {
            if (e.status === v.status) {
              v.count = e.count
            }
          })
        })
      })
    },
    queryReport() {
      this.money = {
        total: 0,
        vx: 0,
        zfb: 0,
        bankCard: 0,
        vxPercentage: 0,
        zgbPercentage: 0,
      }
      this.num = {
        total: 0,
        vx: 0,
        zfb: 0,
        bankCard: 0,
        zfbPercentage: 0,
        vxPercentage: 0,
      }
      this.midLoading = true
      queryReport({
        data: {
          ...this.dateForm,
        },
      }).then((res) => {
        this.midLoading = false
        if (res) {
          res.forEach((e) => {
            this.money.total += Number(e.amount)
            this.num.total += Number(e.total)
            if (e.channelPayWayCode === 'ALIPAY') {
              this.money.zfb = Number(e.amount)
              this.num.zfb = Number(e.total)
            }
            if (e.channelPayWayCode === 'WECHAT') {
              this.money.vx = Number(e.amount)
              this.num.vx = Number(e.total)
            }
          })
          if (this.num.total > 0) {
            this.num.vxPercentage = Number(((this.num.vx / this.num.total) * 100).toFixed(2))
            this.num.zfbPercentage = Number(((this.num.zfb / this.num.total) * 100).toFixed(2))
          }
          if (this.money.total) {
            this.money.total = this.money.total.toFixed(2)
            this.money.vxPercentage = Number(((this.money.vx / this.money.total) * 100).toFixed(2))
            this.money.zfbPercentage = Number(((this.money.zfb / this.money.total) * 100).toFixed(2))
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.wrap {
  min-height: 100%;
  background-color: #f4f4f4;
  padding: 16px;
  min-width: 1300px;
}
.top-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 44px;
  border-bottom: 1px solid #ededed;
  .title-line {
    background-color: #363f47;
    width: 3px;
    height: 18px;
    border: 0px 3px 3px 0px;
    display: inline-block;
    margin-right: 10px;
  }
}
.top {
  background-color: #fff;
  border-radius: 8px;

  .top-bigBox {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px;
    .top-box {
      cursor: pointer;
      flex: 1;
      margin: 0 10px;
      display: flex;
      height: 120px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #ededed;
      color: #363f47;
      .num {
        color: #2861e2;
        font-size: 30px;
        padding-bottom: 6px;
      }
    }
  }
}
.content {
  margin-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  ::v-deep.el-progress-bar__outer,
  ::v-deep.el-progress-bar__inner {
    border-radius: 0px;
  }
  .top-title {
    justify-content: space-between;
    padding-right: 20px;
    .left {
      display: flex;
      align-items: center;
    }
    .time {
      font-weight: normal;
    }
  }
  .mid {
    padding: 20px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .line {
      width: 2px;
      height: 100px;
      background-color: #ccc;
      margin: 0 20px;
    }
    .left,
    .right {
      border: 1px solid #e0e0e0;
      padding: 20px;
      flex: 1;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .text {
        line-height: 40px;
        .money-title {
          font-weight: bold;
        }
        .money {
          color: #2861e2;
          font-size: 30px;
          font-weight: bold;
          .unit {
            font-size: 16px;
          }
        }
        .desc {
          color: #9a9d9e;
          font-size: 14px;
        }
      }
      .line {
        width: 1px;
        height: 50px;
        background-color: #ccc;
      }
      .money-box {
        .money-item {
          color: #363f47;
          line-height: 40px;
          display: flex;
          align-items: center;
          .el-progress {
            width: 200px;
            margin: 0 20px;
          }
          .name {
            min-width: 60px;
          }
        }
      }
    }
    .right {
      flex: 1;
    }
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border: 1px solid #e0e0e0;
    margin: 0 20px;
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .name {
      color: #9a9d9e;
    }
    .text {
      line-height: 40px;
      display: flex;
      align-items: center;
      .line {
        width: 1px;
        height: 60px;
        background-color: #e0e0e0;
        margin-right: 30px;
      }
      .val {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .left {
      min-width: 100px;
      line-height: 50px;
      .name {
        color: #000;
        font-weight: bold;
      }
      .val {
        color: #2861e2;
        font-size: 30px;
        font-weight: bold;
        .unit {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
