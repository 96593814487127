<template>
  <div class="exportDialog">
    <el-form class="export-form">
      <el-form-item label="下单时间：">
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
        <div class="tips">注意：时间快捷选项中的一个月为30天三个月为90天</div>
      </el-form-item>

      <el-form-item label="完成时间：">
        <el-date-picker
          v-model="finishTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
        <div class="tips">注意：时间快捷选项中的一个月为30天三个月为90天</div>
      </el-form-item>

      <el-form-item label="订单状态：">
        <el-select v-if="tabType == '1'" v-model="status" size="mini">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
        <el-select v-if="tabType == '2'" v-model="orderStatus" size="mini">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <div style="text-align: center">
          <el-button size="mini" @click="cancel">取消</el-button>
          <el-button size="mini" type="primary" @click="exportData">导出</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { exportServiceOrder, exportOrderLists } from '@/api/finance'

export default {
  name: '',
  props: {
    tabType: {
      type: String,
      default: '',
    },
  },
  data() {
    var vm = this

    return {
      createTime: '',
      finishTime: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            vm.firstClickTime = minDate
          }
        },
        disabledDate(time) {
          if (vm.firstClickTime === '') {
            return time.getTime() > Date.now()
          } else {
            return time.getTime() > Date.now() || time.getTime() - 15552000000 > vm.firstClickTime.getTime() || time.getTime() + 15552000000 < vm.firstClickTime.getTime()
          }
        },
      },
      isFirstClick: false,
      firstClickTime: '',
      status: '',
      orderStatus: '',
      options1: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待预约',
          value: '1',
        },
        {
          label: '预约失败',
          value: '10',
        },
        {
          label: '待服务-待派单',
          value: '9',
        },
        {
          label: '待服务-待接单',
          value: '2',
        },
        {
          label: '待服务',
          value: '3',
        },
        {
          label: '服务中',
          value: '4',
        },
        {
          label: '待评价',
          value: '5',
        },
        {
          label: '已完成',
          value: '8',
        },
        {
          label: '售后中',
          value: '6',
        },
        {
          label: '已关闭',
          value: '7',
        },
      ],
      options2: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待付款',
          value: '1',
        },
        {
          label: '已付款',
          value: '2',
        },
        {
          label: '已完成',
          value: '4',
        },
        {
          label: '已取消',
          value: '5',
        },
        {
          label: '已关闭',
          value: '6',
        },
      ],
    }
  },

  beforeDestroy() {
    Object.assign(this._data, this.$options.data())
  },

  methods: {
    exportData() {
      if (this.tabType.toString() === '1') {
        const data = {
          data: {
            status: this.status,
            startTime: this.createTime[0] || '',
            endTime: this.createTime[1] || '',
            finishStartTime: this.finishTime[0] || '',
            finishEndTime: this.finishTime[1] || '',
            export: 1,
          },
        }

        const tips = this.$message({
          message: '导出下载中...',
          duration: 0,
          type: 'warning',
        })

        exportServiceOrder(data).then((res) => {
          if (res) {
            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }))
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', '服务订单列表.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)

            tips.close()
            this.$message.success('导出成功！')
          }
        })
      } else {
        const data = {
          data: {
            isAddition: 1,
            orderStatus: this.orderStatus,
            startTime: this.createTime[0] || '',
            endTime: this.createTime[1] || '',
            finishStartTime: this.finishTime[0] || '',
            finishEndTime: this.finishTime[1] || '',
            export: 1,
          },
        }

        const tips = this.$message({
          message: '导出下载中...',
          duration: 0,
          type: 'warning',
        })

        exportOrderLists(data).then((res) => {
          if (res) {
            const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream' }))
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', '加购订单列表.xlsx')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)

            tips.close()
            this.$message.success('导出成功！')
          }
        })
      }
    },

    cancel() {
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.exportDialog {
  .tips {
    color: #9a9d9e;
    font-size: 14px;
  }
}
</style>
