<template>
  <div class="financialExportFile">
    <el-tabs v-model="tabType" class="change-list-tabs">
      <el-tab-pane label="服务订单列表" name="1"></el-tab-pane>
      <el-tab-pane label="加购订单列表" name="2"></el-tab-pane>
    </el-tabs>

    <el-form inline class="search-form">
      <el-form-item label="订单号：">
        <el-input size="mini" :value="activeOrderNo" @input="onInput"></el-input>
      </el-form-item>

      <el-form-item label="订单状态：">
        <el-select v-if="tabType == '1'" v-model="searchForm.status" size="mini">
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>

        <el-select v-if="tabType == '2'" v-model="searchForm.orderStatus" size="mini">
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="下单时间：">
        <el-date-picker
          v-model="createTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="完成时间：">
        <el-date-picker
          v-model="finishTime"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
          size="mini"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">查询</el-button>
        <el-button size="mini" @click="reset">重置</el-button>
        <el-button size="mini" @click="exportData">按条件导出</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="list" border>
      <el-table-column prop="customerName" label="下单用户"></el-table-column>
      <el-table-column :prop="`${tabType == '1' ? 'serviceNo' : 'orderNo'}`" label="订单号"></el-table-column>
      <el-table-column :prop="`${tabType == '1' ? 'singleProductName' : 'productListDtoList[0].productName'}`" label="订单内容"></el-table-column>
      <el-table-column :prop="`${tabType == '1' ? 'payAmount' : 'amount'}`" label="金额"></el-table-column>
      <el-table-column prop="createTime" label="下单时间"></el-table-column>
      <el-table-column v-if="tabType == '1'" prop="appointmentShootDatetime" label="预约时间"></el-table-column>
      <el-table-column v-if="tabType == '1'" prop="finishTime" label="完成时间"></el-table-column>
      <el-table-column :prop="`${tabType == '1' ? 'statusName' : 'orderStatusName'}`" label="状态"></el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px"
      :current-page="currentPage"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="pageSize"
      layout="->,total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <el-dialog v-if="exportDialog" :visible.sync="exportDialog" title="按条件导出" width="600px">
      <export-dialog :tab-type="tabType" @cancel="cancel"></export-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { getServiceOrderLists, getOrderLists } from '@/api/finance'
import exportDialog from './exportDialog.vue'

export default {
  components: {
    exportDialog,
  },
  data() {
    var vm = this

    return {
      ww: '',
      tabType: '1',
      createTime: '',
      finishTime: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick({ maxDate, minDate }) {
          if (!maxDate) {
            vm.firstClickTime = minDate
          }
        },
        disabledDate(time) {
          if (vm.firstClickTime === '') {
            return time.getTime() > Date.now()
          } else {
            return time.getTime() > Date.now() || time.getTime() - 15552000000 > vm.firstClickTime.getTime() || time.getTime() + 15552000000 < vm.firstClickTime.getTime()
          }
        },
      },
      currentPage: 0,
      pageSize: 100,
      searchForm: {
        serviceNo: '',
        status: '',
        orderNo: '',
        orderStatus: '',
      },

      loading: false,
      total: 0,
      list: [],
      selectionList: [],
      exportDialog: false,
      isFirstClick: false,
      firstClickTime: '',
      options1: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待预约',
          value: '1',
        },
        {
          label: '预约失败',
          value: '10',
        },
        {
          label: '待服务-待派单',
          value: '9',
        },
        {
          label: '待服务-待接单',
          value: '2',
        },
        {
          label: '待服务',
          value: '3',
        },
        {
          label: '服务中',
          value: '4',
        },
        {
          label: '待评价',
          value: '5',
        },
        {
          label: '已完成',
          value: '8',
        },
        {
          label: '售后中',
          value: '6',
        },
        {
          label: '已关闭',
          value: '7',
        },
      ],
      options2: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '待付款',
          value: '1',
        },
        {
          label: '已付款',
          value: '2',
        },
        {
          label: '已完成',
          value: '4',
        },
        {
          label: '已取消',
          value: '5',
        },
        {
          label: '已关闭',
          value: '6',
        },
      ],
    }
  },
  computed: {
    activeOrderNo() {
      return this.tabType.toString() === '1' ? this.searchForm.serviceNo : this.searchForm.orderNo
    },
  },
  watch: {
    tabType(val) {
      this.createTime = ''
      this.finishTime = ''
      this.pageNumber = 1
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.getList()
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      if (this.tabType.toString() === '1') {
        const data = {
          data: {
            serviceNo: this.searchForm.serviceNo,
            status: this.searchForm.status,
            startTime: this.createTime[0] || '',
            endTime: this.createTime[1] || '',
            finishStartTime: this.finishTime[0] || '',
            finishEndTime: this.finishTime[1] || '',
          },
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        }

        this.loading = true

        getServiceOrderLists(data).then((res) => {
          if (res) {
            this.total = Number(res.total)
            this.list = [...res.content]
            this.loading = false
          }
        })
      } else {
        const data = {
          data: {
            orderNo: this.searchForm.orderNo,
            orderStatus: this.searchForm.orderStatus,
            startTime: this.createTime[0] || '',
            endTime: this.createTime[1] || '',
            finishStartTime: this.finishTime[0] || '',
            finishEndTime: this.finishTime[1] || '',
            isAddition: 1,
          },
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
        }

        this.loading = true

        getOrderLists(data).then((res) => {
          if (res) {
            this.total = Number(res.total)
            this.list = [...res.content]
            this.loading = false
          }
        })
      }
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },

    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    },

    exportData() {
      this.exportDialog = true
    },

    search() {
      this.currentPage = 1
      this.getList()
    },

    onInput(e) {
      if (this.tabType.toString() === '1') {
        this.searchForm.serviceNo = e
      } else {
        this.searchForm.orderNo = e
      }

      this.searchForm = { ...this.searchForm }
    },

    cancel() {
      this.exportDialog = false
    },

    reset() {
      Object.assign(this._data.searchForm, this.$options.data().searchForm)
      this.currentPage = 1
      this.createTime = ''
      this.finishTime = ''
      this.getList()
    },
  },
}
</script>

<style lang="scss" scoped>
.financialExportFile {
  background: #fff;
  padding-left: 20px;

  .change-list-tabs {
    // padding-left: 20px;
  }

  .search-form {
    ::v-deep .el-range-editor--mini.el-input__inner {
      width: 316px;
    }
  }
}
</style>
